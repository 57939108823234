<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="12"
            class="d-flex align-items-center justify-content-end"
          >
            <v-date-picker
              v-model="query.searchDate"
              type="month"
              value-type="YYYYMM"
              class="el-def"
              style="width: 150px"
            />
            <label class="mr-1">(*당일데이터는 포함되지 않음)</label>
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="fetchData()"
            >
              조회
            </b-button>
            <b-button
              variant="outline-dark"
              class="btn-search ml-1"
              @click.prevent="excelDownload()"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>엑셀 다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card>
      <b-table-simple
        bordered
        responsive
        sticky-header
        style="max-height: 65vh;"
      >
        <colgroup>
          <col style="width: 7%">
          <col>
          <col style="width: 7%">
          <col style="width: 18%">
          <col style="width: 12%">
          <col style="width: 12%">
          <col style="width: 12%">
        </colgroup>
        <b-thead head-variant="light">
          <b-tr>
            <b-th>ID</b-th>
            <b-th>이벤트 제목</b-th>
            <b-th>회원 구분</b-th>
            <b-th>진료과</b-th>
            <b-th>클릭 수</b-th>
            <b-th>참여 완료 수</b-th>
            <b-th>보상 지급 건수</b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-if="eventData.length > 0">
          <template v-for="item in eventData">
            <template v-for="(member, index) in item.memberStatistics">
              <b-tr
                :key="`member-${item.id}-${index}`"
              >
                <b-td
                  v-if="index === 0"
                  :rowspan="(item.memberStatistics.length > 0 ? item.memberStatistics.length + 1 : 0) + (item.noneMemberStatistics.length > 0 ? item.noneMemberStatistics.length + 1 : 0) + 1"
                >
                  {{ item.id }}
                </b-td>
                <b-td
                  v-if="index === 0"
                  :rowspan="(item.memberStatistics.length > 0 ? item.memberStatistics.length + 1 : 0) + (item.noneMemberStatistics.length > 0 ? item.noneMemberStatistics.length + 1 : 0) + 1"
                >
                  {{ item.title }}
                </b-td>
                <b-td
                  v-if="index === 0"
                  :rowspan="item.memberStatistics.length"
                >
                  회원
                </b-td>
                <b-td>{{ member.medicalDept }}</b-td>
                <b-td>{{ member.clickCount | $numberFormatter }}</b-td>
                <b-td>{{ member.joinCount | $numberFormatter }}</b-td>
                <b-td>{{ member.rewardCount | $numberFormatter }}</b-td>
              </b-tr>
            </template>
            <b-tr
              v-if="item.memberStatistics.length > 0"
              :key="`member-total-${item.id}`"
            >
              <b-td
                colspan="2"
                style="text-align: center;"
              >
                소계
              </b-td>
              <b-td>{{ item.memberStatistics.reduce((f, l) => f + l.clickCount, 0) | $numberFormatter }}</b-td>
              <b-td>{{ item.memberStatistics.reduce((f, l) => f + l.joinCount, 0) | $numberFormatter }}</b-td>
              <b-td>{{ item.memberStatistics.reduce((f, l) => f + l.rewardCount, 0) | $numberFormatter }}</b-td>
            </b-tr>
            <template v-for="(member, index) in item.noneMemberStatistics">
              <b-tr :key="`nonemember-${item.id}-${index}`">
                <b-td
                  v-if="index === 0 && item.memberStatistics.length === 0"
                  :rowspan="(item.memberStatistics.length > 0 ? item.memberStatistics.length + 1 : 0) + (item.noneMemberStatistics.length > 0 ? item.noneMemberStatistics.length + 1 : 0) + 1"
                >
                  {{ item.id }}
                </b-td>
                <b-td
                  v-if="index === 0 && item.memberStatistics.length === 0"
                  :rowspan="(item.memberStatistics.length > 0 ? item.memberStatistics.length + 1 : 0) + (item.noneMemberStatistics.length > 0 ? item.noneMemberStatistics.length + 1 : 0) + 1"
                >
                  {{ item.title }}
                </b-td>
                <b-td
                  v-if="index === 0"
                  :rowspan="item.noneMemberStatistics.length"
                >
                  비회원
                </b-td>
                <b-td>{{ member.medicalDept }}</b-td>
                <b-td>{{ member.clickCount | $numberFormatter }}</b-td>
                <b-td>{{ member.joinCount | $numberFormatter }}</b-td>
                <b-td>{{ member.rewardCount | $numberFormatter }}</b-td>
              </b-tr>
            </template>
            <b-tr
              v-if="item.noneMemberStatistics.length > 0"
              :key="`nonemember-total-${item.id}`"
            >
              <b-td
                colspan="2"
                style="text-align: center;"
              >
                소계
              </b-td>
              <b-td>{{ item.noneMemberStatistics.reduce((f, l) => f + l.clickCount, 0) | $numberFormatter }}</b-td>
              <b-td>{{ item.noneMemberStatistics.reduce((f, l) => f + l.joinCount, 0) | $numberFormatter }}</b-td>
              <b-td>{{ item.noneMemberStatistics.reduce((f, l) => f + l.rewardCount, 0) | $numberFormatter }}</b-td>
            </b-tr>
            <b-tr :key="`total-${item.id}`">
              <b-td
                colspan="2"
                style="text-align: center;"
              >
                합계
              </b-td>
              <b-td>{{ item.memberStatistics.reduce((f, l) => f + l.clickCount, 0) + item.noneMemberStatistics.reduce((f, l) => f + l.clickCount, 0) | $numberFormatter }}</b-td>
              <b-td>{{ item.memberStatistics.reduce((f, l) => f + l.joinCount, 0) + item.noneMemberStatistics.reduce((f, l) => f + l.joinCount, 0) | $numberFormatter }}</b-td>
              <b-td>{{ item.memberStatistics.reduce((f, l) => f + l.rewardCount, 0) + item.noneMemberStatistics.reduce((f, l) => f + l.rewardCount, 0) | $numberFormatter }}</b-td>
            </b-tr>
          </template>
        </b-tbody>

        <b-tbody v-else>
          <b-tr>
            <b-td colspan="6">
              <div
                role="alert"
                aria-live="polite"
              >
                <div class="text-center my-2">
                  일치하는 데이터가 없습니다.
                </div>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import { onMounted, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BTableSimple, BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  setup() {
    onMounted(() => {
      fetchData()
    })

    const toast = useToast()

    const query = ref({
      searchDate: dayjs().format('YYYYMM'),
    })

    const eventData = ref([])

    const fetchData = () => {
      axios.get(`/fa/statistics/event`, {
        params: {
          searchDate: query.value.searchDate ? dayjs(query.value.searchDate).date(1).format('YYYY-MM-DD') : null,
        },
      })
        .then(rs => {
          eventData.value = rs.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 조회 중 오류가 발생하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const excelDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.columns = [
        { header: "ID", key: "id", width: 15 },
        { header: "이벤트 제목", key: "title", width: 50 },
        { header: "회원 구분", key: "memberType", width: 10 },
        { header: "진료과", key: "medicalDept", width: 20 },
        {
          header: "클릭 수", key: "clickCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: "참여 완료 수", key: "joinCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: "보상 지급 건수", key: "rewardCount", width: 15, style: { numFmt: '#,##0' },
        },
      ]

      let startRow = 2
      let endRow = startRow

      // eslint-disable-next-line array-callback-return
      eventData.value.map(e => {
        e.memberStatistics.map(x => worksheet.addRow({
          id: e.id,
          title: e.title,
          memberType: '회원',
          medicalDept: x.medicalDept,
          clickCount: x.clickCount,
          joinCount: x.joinCount,
          rewardCount: x.rewardCount,
        }))

        if (e.memberStatistics.length > 0) {
          worksheet.addRow({
            id: e.id,
            title: e.title,
            memberType: '소계',
            medicalDept: '',
            clickCount: e.memberStatistics.reduce((f, l) => f + l.clickCount, 0),
            joinCount: e.memberStatistics.reduce((f, l) => f + l.joinCount, 0),
            rewardCount: e.memberStatistics.reduce((f, l) => f + l.rewardCount, 0),
          })

          worksheet.mergeCells(`C${endRow}:C${endRow + e.memberStatistics.length - 1}`)

          endRow += e.memberStatistics.length

          worksheet.mergeCells(`C${endRow}:D${endRow}`)

          endRow += 1
        }

        e.noneMemberStatistics.map(x => worksheet.addRow({
          id: e.id,
          title: e.title,
          memberType: '비회원',
          medicalDept: x.medicalDept,
          clickCount: x.clickCount,
          joinCount: x.joinCount,
          rewardCount: x.rewardCount,
        }))

        if (e.noneMemberStatistics.length > 0) {
          worksheet.addRow({
            id: e.id,
            title: e.title,
            memberType: '소계',
            medicalDept: '',
            clickCount: e.noneMemberStatistics.reduce((f, l) => f + l.clickCount, 0),
            joinCount: e.noneMemberStatistics.reduce((f, l) => f + l.joinCount, 0),
            rewardCount: e.noneMemberStatistics.reduce((f, l) => f + l.rewardCount, 0),
          })

          worksheet.mergeCells(`C${endRow}:C${endRow + e.noneMemberStatistics.length - 1}`)

          endRow += e.noneMemberStatistics.length

          worksheet.mergeCells(`C${endRow}:D${endRow}`)

          endRow += 1
        }

        worksheet.addRow({
          id: e.id,
          title: e.title,
          memberType: '합계',
          medicalDept: '',
          clickCount: e.memberStatistics.reduce((f, l) => f + l.clickCount, 0) + e.noneMemberStatistics.reduce((f, l) => f + l.clickCount, 0),
          joinCount: e.memberStatistics.reduce((f, l) => f + l.joinCount, 0) + e.noneMemberStatistics.reduce((f, l) => f + l.joinCount, 0),
          rewardCount: e.memberStatistics.reduce((f, l) => f + l.rewardCount, 0) + e.noneMemberStatistics.reduce((f, l) => f + l.rewardCount, 0),
        })

        worksheet.mergeCells(`C${endRow}:D${endRow}`)
        worksheet.mergeCells(`A${startRow}:A${endRow}`)
        worksheet.mergeCells(`B${startRow}:B${endRow}`)

        startRow = endRow + 1
        endRow = startRow
      })

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          if (cell.value === '소계' || cell.value === '합계') {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()

      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "이벤트 통계.xlsx")
    }

    return {
      query,
      eventData,
      fetchData,
      excelDownload,
    }
  },
}
</script>

<style scoped>
.table-bordered th, td {
  border: 1px solid #dee2e6;
}
</style>
